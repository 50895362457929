import React from "react";
import Img from "gatsby-image";

const Header = ({ data }) => (
  <div className="about section" id="About">
    <div className="container">
      <div className="about-main">
        <div className="about-details">
          <ul className="details">
            <li>
              <strong>Date of birth</strong>
              <p>{data.age}</p>
            </li>
            <li>
              <strong>Location</strong>
              <p>Hokkaido</p>
            </li>
            {/* <li>
              <strong>Email</strong>
              <p>
                <a href={`mailto:${data.gmail}`}>{data.gmail}</a>
              </p>
            </li> */}
            <li>
              <strong>Languages</strong>
              <p>
              Golang, Scala, Dart, Swift, Python, Java, PHP, Fortran, C, JavaScript, TypeScript, HTML, CSS
              </p>
            </li>
            <li>
              <strong>Frameworks</strong>
              <p>
              Echo, go-swagger, Flutter, Play, Laravel, Django/DRF, React, Vue
              </p>
            </li>
            <li>
              <strong>Infrastructure</strong>
              <p>
                AWS, Firebase, k8s
              </p>
            </li>
          </ul>

          <div className="socials">
            <ul>
              <li>
                <a
                  className="fab fa-github"
                  href={data.github}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Header;
